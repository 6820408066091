/* eslint-disable import/no-anonymous-default-export */
export default {
  autocomplete: {
    start_typing: 'Commencez à écrire...',
    individual_text: 'Écrire une ville ou un état ou un pays',
    lev_text: 'Écrire une ville ou un état ou un pays',
    no_options: 'aucune option',
  },
  featureNotInPlan:"Cette fonctionnalité n'est pas disponible dans votre plan.",
  acknowledgement: 'Je reconnais et accepte la politique de Valital',
  agreement: "J'accepte de",
  announcement: {
    '2fa': {
      title: 'Protégez votre compte avec <br /> la vérification à deux étapes',
      description: `La vérification à deux étapes vous permet de connecter un numéro de téléphone de confiance à votre compte Valital,
      et l'utiliser pour confirmer votre identité lors de la connexion.`,
      note: "Votre organisation a besoin d'activer la vérification en deux étapes",
      action_main: 'Activer la vérification à deux étapes',
      action_secondary: 'Non Merci',
    },
  },
  lev_not_in_plan: {
    title: 'Fonctionnalité non disponible',
    description_1:
      "Cette fonctionnalité n'est pas disponible dans votre forfait.",
    description_2: "Veuillez contacter Valital pour l'activer.",
  },
  back_to_home: 'retour à la page principale',
  continue: 'Continuez',
  change_plan_costs_note:
    "<small>Note : l'annulation prématurée de votre plan actuel engendrera des frais de <strong>{{costs}}</strong>.</small>",
  change_plan_invoice_will_be_sent:
    'Une facture vous sera envoyée dans les prochaines 24 heures.',
  free_trial: "Vous utilisez l'essai gratuit: {{remaining}} jours restants.",
  plan_expired: 'Votre plan est expiré.',
  mark_all_as_read: 'Cocher le tout comme lu',
  last_validated: 'Dernière mise à jour le {{date}}',
  live_update_description:
    'EnContinu surveillera quotidiennement les medias en ligne et vous informera de toute nouvelle information.',
  include_twitter_description: `Activez Twitter pour voir les noms d’utilisateurs potentiels et les tweets signalés associés à cet individu.`,
  extend_report_description:
    'Activez cette fonctionnalité pour inclure les résultats d’inconduite inférieurs à notre seuil de correspondance d’identification. Attendez-vous à plus de faux positifs en conséquence.',
  not_yet_validated: 'Pas encore validé',
  new_version_available: 'Une nouvelle version est disponible',
  update_now: 'Mettez à jour maintenant',
  detail_view: 'Vue de détails',
  grid_view: 'Vue en grille',
  on_date: 'le {{date}}',
  search_placeholder: 'Recherchez par nom',
  search_show_all: 'Afficher tous les résultats pour {{value}}',
  something_went_wrong:
    "Oops, quelque chose n'a pas fonctionné ! Veuillez réessayer plus tard",
    team_validations_depleted: "Toutes les validations allouées à votre équipe ont été utilisé. Veuillez mettre à jour le nombre de validations allouées à votre équipe afin d'effectuer des validations.",
  organization_submit_succes: 'Votre validation pour {{name}} a été soumise',
  thank_you_for_your_input: 'Merci pour votre contribution',
  view_all: 'visionner le tout',
  view_all_with_count: 'Visionner le tout ({{count}}) →',
  notifications: {
    a_referral: 'une référence',
    creator_added_subject: '{{creator_name}} a ajouté {{subject_name}}',
    creator_changed_status:
      '{{creator_name}} a changé le status de {{subject_name}}',
    from_to: 'de "{{previous}}" à "{{next}}"',
    has_been_validated: 'a été validée',
    has_been_declined: 'a été refusé',
    in_subject_behaviour: 'dans les habitudes de {{subject_name}}',
    on_date: 'le {{date}}.',
    one_of: "L'une des",
    significant_change_noted: 'un changemment important a été noté',
    subject_added: '{{subject_name}} a ajouté',
    subject_changed_status:
      '{{subject_name}} a changé le statut du consentement',
    subject_deleted: '{{subject_name}} a effacé',
    subject_referrals: 'références de {{subject_name}}',
    subject_removed: '{{subject_name}} a supprimé',
    subject_updated: '{{subject_name}} mis à jour',
    their_account: 'son compte',
    to_the_company:
      "à la liste des utilisateurs de l'entreprise de type {{user_type}}",
    human_curator_updated_report:
      'Le rapport de {{subject_name}} est disponible',
    refused: 'Refusé',
    accepted: 'Accepté',
    pending: 'En attente',
    load_more: 'Voir Plus',
    active: 'Actif',
    inactive: 'Inactif',
  },
  pages: {
    activate: {
      almost_there: 'encore une étape et votre inscription est terminée',
      finish_registering:
        'Finalisez votre inscription et commencez à utiliser Valital',
      stand_out: 'Démarquez votre compagnie en ajoutant un logo (optionnel)',
      complete_registration: "Terminez l'enregistrement",
    },
    billing: {
      add_a_card: 'Ajoutez une carte',
      back_to_plans: 'Retour aux plans tarifaires',
      send_invoices_to: 'Nous enverrons des factures à ce courriel',
    },
    business_plans_modal: {
      plans_for_every_org:
        'Peu importe la taille de votre organisation nous avons un plan pour vous.',
    },
    import_csv_form: {
      title: 'Import Individuals',
      description: 'Upload a CSV file to easily add multiple individuals',
    },
    dashboard: {
      delete_talent: 'Effacer le talent',
      empty_dashboard: {
        title: 'Bienvenue sur Valital!',
        subtitle:
          "Invitez votre premier candidat ou employé et recevez le rapport de son comportement en ligne et de ses références quelques minutes après que l'invitation est acceptée",
        button_text: 'Invitez votre premier talent',
      },
      cancel: {
        title: 'Annuler la demande de consentement ?',
        body: 'En annulant la demande, {{first_name}} {{last_name}} sera supprimé de votre entreprise.',
      },
      delete: {
        title: 'Supprimer {{first_name}}?',
        body: 'Êtes-vous sûr de vouloir supprimer {{first_name}} {{last_name}} de votre entreprise?',
      },
      live_validation_not_available: {
        title: 'Votre plan ne permet pas les alertes EnContinu',
        description:
          "Cette fonctionnalité n'est disponible que pour les plans business. Modifiez votre plan pour accéder à la fonctionnalité!",
      },
      pending_for_more_than_5_days: 'En attente depuis plus de 5 jours',
      columns_to_display: 'Colonnes à afficher',
    },
    delegates: {
      title: 'Vos utilisateurs délégués',
      deactivate: {
        title: 'Désactiver {{first_name}} {{last_name}}?',
        body: "La désactivation d’un utilisateur l'empêchera d'avoir un accès d’utilisateur délégué à cette entreprise.",
      },
      select_user_type: 'Choisir une permission',
    },
    export_to_csv: 'Exporter vers csv',
    download: 'Télécharger',
    login: {
      '2fa': {
        title: 'Entrez le code de sécurité pour continuer',
        new_device:
          "Il semble s'agir d'un nouvel appareil, d'un navigateur ou d'un nouvel emplacement. Saisissez le code de sécurité que vous avez reçu d'un SMS ou de votre application d'authentification.",
        remember_me: 'Souvenez-vous de cet appareil pour {{ days }} jours',
        issues: 'Vous rencontrez des problèmes? Essayez une autre façon',
        issues_details: 'Contactez-nous',
      },
      title: 'Bienvenue à Valital',
      forgot_password: 'Avez-vous oublié votre mot de passe?',
      start_free_plan: 'Commencez gratuitement',
      invalid_code: 'Code invalide. Veuillez réessayer.',
      account_no_access: `Le compte n'a pas accès`,
      attemps_excceeded:
        'Tentatives de mot de passe dépassées. Veuillez réessayer dans 2 minutes.',
      temporary_password_expired: 'Votre mot de passe temporaire a expiré.',
      temp_pass_click_here: 'Cliquez ici',
      temp_pass_reset: 'pour le réinitialiser.',
      temp_pass_sent: 'Votre mot de passe temporaire a été envoyé.',
      mfa_code_resent: 'Un nouveau code a été envoyé.',
    },
    profile: {
      '2fa': {
        title: 'Entrez votre numéro de cellulaire',
        subtitle:
          'Gardez votre compte sécurisé avec une deuxième étape de vérification',
        enabled: 'Activée avec le numéro de téléphone {{ phone_number }}',
        subtitle_admin:
          'Sécurisez encore plus le compte de votre organisation avec une deuxième étape de vérification',
        insert_code: "Entrez le code qu'on vous a envoyé par SMS",
        change_number: 'Changer le numéro',
      },
      leave_blank:
        'Laissez vide si vous ne désirez pas modifier votre mot de passe',
      password_changed: 'Le mot de passe a été modifié avec succès',
      delete: {
        delegate: {
          title: 'Annulation du compte',
          body1: 'Contactez',
          body2: "pour procéder à l'annulation de votre compte",
        },
      },
    },
    referrals: {
      score_1: "Le courriel de référence n'a pas un domaine de compagnie",
      score_2:
        '{{company}} est introuvable dans la base de données d’entreprises fédérales canadiennes',
      score_3:
        'Le courriel de référence pourrait ne pas appartenir à {{company}}',
      worked_as: 'Rôle ',
      work_period: 'Période de travail {{period}}',
      reference_status: 'Statut de la référence{{updated}}',
      details: 'Détails',
      reference_name: 'Nom de la référence',
      reference_email: 'Courriel',
      request_reference: {
        text: "Votre talent n'a pas encore partagé de références avec vous.<br/>Demandez une référence en cliquant sur le bouton ci-dessus",
        button: 'Demandez une reference',
      },
    },
    register: {
      intro: {
        title: 'Enregistrez-vous<1> gratuitement </1>en 2 étapes simples.',
        free_trial: 'Aucune carte de crédit nécessaire',
        card_1: 'Présentez-nous votre compagnie',
        card_2: 'Parlez-nous de vous',
      },

      title_step_1: 'Parlez-nous de votre compagnie',
      input_label_step1_1: 'Nom de la compagnie ',
      input_label_step1_2: "Nombre d'employés ",

      title_step_2: 'Maintenant, parlez-nous de vous',
      input_label_step2_1: 'Courriel professionnel',

      terms_of_use: "J'accepte <2>les conditions d'utilisation</2> de Valital",
      sign_up: "S'inscrire",

      success: {
        title: 'Votre demande a été soumise',
        next: 'Ensuite: vérifiez votre courriel',
        description: `
                Nous vous avons envoyé un courriel à <span>{{email}}</span> avec plus d'informations sur les prochaines étapes
                <br/><br/>
                Merci de votre intérêt pour Valital!`,
      },
    },
    reset_password: {
      title: 'Mot de passe oublié?',
      title_done: 'Merci!',
      body: 'Veuillez entrer votre adresse courriel pour réinitialiser votre mot de passe',
      body_done:
        'Le code de réinitialisation du mot de passe a été envoyé. Veuillez vérifier votre boîte de réception.',
    },
    search_results: {
      title: 'Tous les résultats pour {{search}}',
      your_talents: 'Vos talents',
      your_talents_SECURITY: 'Individus',
      not_your_company: 'Ne font pas partie de votre entreprise',
    },
    update_password: {
      body: 'Veuillez saisir un nouveau mot de passe pour votre compte',
    },
    sso:{
      body:'Veuillez saisir le domaine de votre organisation'
    },
    mfa_request: {
      title: 'Authentification à deux facteurs',
      body: 'Entrez le code unique que vous avez reçu',
      not_received: "Vous n'avez pas reçu de code ?",
      button: 'Vérifier',
    },
    plans: {
      change_plan: 'Passer à un plan {{newPlanType}}?',
      change_plan_final_step:
        'Votre changement sera effectif dès la complétion de cette étape.',
      no_billing_info:
        'Aucune information de facturation trouvée dans le dossier',
      no_billing_info_description:
        'Une carte de crédit est requise pour mettre à niveau votre plan',
      update_billing_info: 'Mettre à jour les informations de facturation',
      invalid_plan: 'Plan tarifaire invalide',
      company_not_registered: 'Le plan de votre compagnie a expiré',
      company_not_registered_details:
        "Veuillez contacter votre administrateur pour vous assurer d'avoir un abonnement actif",
      unable_to_process_payment:
        "Nous n'avons pas pu traiter le paiement de ce mois",
      update_billing: 'Mettre à jour la facturation',
      missing_billing_info:
        "Vous n'avez pas de détails de facturations dans le dossier. Ajoutez-les avant votre prochain paiement!",
    },
    plans_and_payments: {
      view_details: 'Voir détails',
      current_plan: 'Plan en cours: {{plan_name}}',
      billing_cycle_ends: 'Renouvellement du plan: {{date}}',
      next_payment: 'Prochain paiement {{next_payment}}',
      validations_made: 'Validations effectuées',
      validations_remaining: 'validations restantes jusqu’au',
      manage_subscription: 'Gérer votre abonnement',
      your_plan_breakdown: 'La ventilation de votre plan pour ce mois:',
      purchased_bundles: 'Bundles ajoutés à ce plan',
      available_bundles:
        'Vous avez presque atteint la limite de votre plan? Ajouter des validations avec un achat unique.',
      bundle_types: {
        additional_validations: 'validations',
        additional_users: 'utilisateurs',
      },
      will_be_added:
        '{{count}} {{type}} vont être ajouté(e)s à votre abonnement.',
      will_be_charged: 'Vous serez facturé dès que vous cliquez sur "Acheter"',
      were_be_added:
        '{{count}} {{type}} ont été ajouté(e)s à votre abonnement.',
      bundle_unavailable: 'Forfait non disponible',
      bundle_unavailable_free_trial:
        "Vous ne pouvez pas ajouter de bundles avec l'essai gratuit",
      bundle_unavailable_limit:
        'Vous devez être plus prêt des limites de votre plan pour ajouter un bundle',
      you_sure_cancel: 'Êtes-vous certain de vouloir annuler?',
      cancel_description_first:
        'Vous allez perdre accès à la plateforme et vous serez chargé',
      cancel_description_second:
        'selon le nombre de validations utilisées dans votre compte',
      cancel_successful: 'Votre abonnement a été annulé avec succès',
      questions: 'Des questions? Consultez nos',
      online_conduct_report: 'Rapports de conduite en ligne',
      unlimited_job_confirmation:
        'Confirmations des références d’emploi illimitées',
      users_per_company: 'utilisateurs par compagnie',
    },
    user_management: {
      teams: {
        type_PRIVATE: 'Privé',
        type_SHARED: 'Partagé',
        team: 'Équipe',
        teams: 'Équipes',
        type: 'Type',
        users: 'Utilisateurs',
        allocated_validations: 'Validations attribuées',
        available_validations: 'Validations disponibles',
        validations_available: '{{validations}} validations disponibles',
        add_team: 'Ajouter une équipe',
        edit_team: "Modifier l'équipe",
        delete_team: "Supprimer l'équipe",
        delete_step1_body: `La suppression de l'équipe {{team}} déplacera tous les utilisateurs vers l'équipe générale. Les validations restantes seront ajoutées au quota du compte. Vous avez la possibilité de déplacer les validations de cette équipe vers une autre ou de les supprimer définitivement. Tapez "Supprimer l'équipe" pour confirmer la suppression de l'équipe.`,
        delete_button_1: 'Supprimer et déplacer les validations',
        delete_button_2: 'Effacé définitivement',
        delete_button_3: 'Retour',
        delete_button_4: 'Supprimer',
        private_validations: 'Validations privées',
        private_validations_info: "Autoriser les membres de l'équipe à soumettre des validations qui ne seront pas partagées avec les autres membres de l'équipe",
        disabled: 'Désactivé',
        enabled: 'Activé',
        min_vals_err: 'Le nombre de validations doit être supérieur ou égal à 0.'
      },
      users: {
        user: 'Utilisateur',
        access_type: "Type d'accès",
        status: 'Statut',
        status_ACTIVE: 'Actif',
        status_INACTIVE: 'Inactif',
        add_user: 'Ajouter un utilisateur',
        edit_user: "Modifier l'utilisateur",
        role_ADMIN: 'Administrateur du compte',
        role_OWNER: 'Responsable du compte',
        role_LEAD: 'Administrateur de l’équipe',
        role_MEMBER: 'Membre de l’équipe'
      }
    },
    lev_report: {
      summary_details: {
        box_1:
          'Les résultats ci-dessous rapportent des informations en ligne défavorables concernant les facteurs environnementaux, sociaux et de gouvernance, selon les normes et le cadre ESG couramment reconnus.',
        box_2:
          'Les rapports signalés en jaune mentionnent l’entreprise désignée et contiennent des informations négatives en ligne en lien avec le pilier ESG correspondant.',
        box_3:
          "Un indicateur vert signifie qu'aucune information en ligne défavorable en lien avec l'ESG n'a été saisie par nos modèles d'IA.",
        ENVIRONMENT: {
          title: 'Environnement',
          description:
            "Les résultats proviennent des informations publiées en ligne qui mentionnent le nom de l’organisation dans des événements liés à l’environnement. L’indicateur jaune signifie que des comportements défavorables aux critères environmentaux des directives ESG impliquant l'organisation sont signalés.",
        },
        SOCIAL: {
          title: 'Social',
          description:
            "Les résultats proviennent des informations publiées en ligne qui mentionnent le nom de l’organisation dans des événements liés au volet social. L’indicateur jaune signifie que des comportements défavorables aux critères sociaux des directives ESG impliquant l'organisation sont signalés.",
        },
        GOVERNANCE: {
          title: 'Gouvernance',
          description:
            "Les résultats proviennent des informations publiées en ligne qui mentionnent le nom de l’organisation dans des événements liés à des sujets de gouvernance. L’indicateur jaune signifie que des comportements défavorables aux critères de gouvernance des directives ESG impliquant l'organisation sont signalés.",
        },
        OTHER: {
          title: 'Autre',
          description:
            "Les résultats médiatiques défavorables au sujet de l’organisation sont signalés dans cette section lorsqu'ils n’impliquent pas les directives ESG mondialement reconnues.",
        },
      },
      categories: {
        ENVIRONMENT: 'Environnement',
        SOCIAL: 'Social',
        GOVERNANCE: 'Gouvernance',
        OTHER: 'Autre',
      },
    },
    report: {
      summary: {
        words: '{{count}} mots',
        view: 'VIsualiser le résumé',
        request: 'Résumer l\'article',
        loading: 'Chargement...',
        error: 'Le résumé de cet article n\'est pas disponible. Veuillez raffraichir votre page et réessayer.',
        noCredits: 'No more credits!',
        creditsError: 'Les {{count}} résumés disponibles ont été utilisé. Veuillez contacter l\'administrateur du compte pour faire une demande de résumés additionnels à support@valital.com. ',
        requestMore: 'Demander plus',
      },
      verified_estimate: {
        validation_under_review:
          "Votre validation la plus récente est en cours d'examen.",
        ready_shortly: 'Le rapport devrait être prêt sous peu...',
        ready_monday: `Le rapport mis à jour sera disponible lundi à 13 heures. Nous vous informerons par courriel lorsqu'il sera prêt.`,
        ready_in_24: `Le rapport mis à jour sera disponible dans les {{hours}}h. Nous vous informerons par courriel lorsqu'il sera prêt.`,
      },
      history: {
        history: 'Historique',
        no_activities: "Cet utilisateur n'a aucune activité",
        activities: {
          toggled_live_update:
            '{{user}} a activé les alertes EnContinu pour {{employment}}',
          toggled_live_update_off:
            '{{user}} a desactivé les alertes EnContinu pour {{employment}}',
          added_individual:
            '{{employment}} a été ajouté à votre entreprise par {{user}}',
          added_talent:
            '{{individual}} a été invité dans votre entreprise par {{user}}',
          invitation_accepted:
            "{{employment}} a accepté l'invitation de votre entreprise.",
          invitation_declined:
            "{{employment}} a décliné l'invitation de votre entreprise.",
          started_validation:
            'Une validation a été commencé par {{user}}',
          started_twitter_validation:
            'Une analyse Twitter a été lancée par <2>{{user}}</2>.',
          preliminary_report: 'Le rapport préliminaire est prêt.',
          published_validation:
            'Le rapport de {{name}} est mise à jour suite',
          published_validation_plural:
            '{{newArticles}} nouveaux articles ont été trouvés',
          published_validation_twitter:
            '{{newArticles}} nouveaux tweets ont été trouvés',
          published_validation_twitter_plural:
            '{{newArticles}} nouveaux tweets ont été trouvés',
          from_live_update: ' à une alerte EnContinu',
          conduct_changed: "Le status de l'individu est:",
          conduct_unchanged: "Le status de l'individu n'a pas changé:",
          lev_conduct_changed: "Le statut de l'organisation est:",
          lev_conduct_unchanged: "Le rapport de l'organisation n'a pas changé:",
          sharing_reference_shared:
            '<0>{{employment}}</0> a partagé une référence de <2>{{reference}}</2> avec votre entreprise.',
          sharing_reference_unshared:
            '<0>{{employment}}</0> a supprimé une référence de <2>{{reference}}</2> avec votre entreprise.',
          confirming_reference_confirmed:
            "Une référence de <1>{{reference}}</1> a confirmé l'emploi de <4>{{employment}}</4>.",
          confirming_reference_declined:
            "Une référence de <1>{{reference}}</1> a refusé l'emploi de <4>{{employment}}</4>.",
        },
      },
      strong_id_match: 'Identifiant fort',
      weak_id_match: 'Identifiant faible',
      very_weak_id_match: 'Identifiant très faible',
      percentage_id: '{{percentage}} %',
      more_than_90: 'Plus de 90',
      accused: "Sujet de l'action",
      victim: "Pas le sujet de l'action",
      english: 'EN',
      french: 'FR',
      spanish: 'ES',
      portuguese: 'PT',
      undefined: "Sujet de l'action non analysé",
      showing_results: 'Affichage de {{count}} résultat',
      showing_results_plural: 'Affichage de {{count}} résultats',
      empty_conduct: {
        title: 'Votre rapport est en cours!',
        description:
          'Rapide est bon; la précision c’est encore mieux. Nous savons ce que vous appréciez le plus.',
        lev_description:
          'De bons rapports arrivent à ceux qui attendent... juste un peu plus longtemps.',
      },
      extended_report: {
        notice:
          'Les résultats ci-dessous peuvent inclure des articles inférieurs à notre seuil de correspondance d’identification. Attendez-vous à plus de faux positifs puisque la recherche étendue a été activée.',
      },
      categories: {
        'HIGH RISK': 'Risque élevé',
        INVESTIGATE: 'Examiner',
        CLEAR: 'Rien à signaler',
        TWITTER: 'Tweets Signalés',
      },
      languageOptions: {
        english: "Anglais",
        french: "Français",
        spanish: "Espagnol",
        portuguese: "Portugais"
      },
      filters: {
        category: 'Catégorie',
        filter_articles: 'Filtrer les articles',
        topics: 'Sujets',
        general: {
          idMatch: {
            main: 'Corrélation de l’Identifiant',
            STRONG: {
              main: 'Fort (80%+)',
              sentence: 'Identifiant fort',
            },
            WEAK: {
              main: 'Faible (61 à 79%)',
              sentence: 'Identifiant faible',
            },
            VERY_WEAK: {
              main: 'Identifiant très faible(40 à 60%)',
              sentence: 'Identifiant très faible',
            },
          },
          language: {
            main: "Langue de l'article",
            ENGLISH: {
              main: 'EN',
              sentence: 'Anglais',
            },
            FRENCH: {
              main: 'FR',
              sentence: 'Français',
            },
            SPANISH: {
              main: 'ES',
              sentence: 'Espagnol'
            },
            PORTUGUESE: {
              main: 'PT',
              sentence: 'Portugais' 
            }

          },
          sourceCategory: {
            main: 'Source',
            mainstream_media: {
              sentence: 'Médias traditionnels',
            },
            government_agencies: {
              sentence: 'Organismes gouvernementaux',
            },
            legitimate_online_database: {
              sentence: 'Bases de données légitimes',
            },
            education_professional_associations: {
              sentence: 'Institutions reconnues',
            },
            strong_content: {
              sentence: 'Qualité de contenu élevée',
            },
            user_source: {
              sentence: 'Mes Sources',
            },
            not_verified: {
              sentence: 'Non Vérifiée',
            }

          },
          articleType: {
            main: "Type d'article",
            LIVE: {
              main: 'En Continu',
              sentence: 'Articles en continu',
            },
            INSTANT: {
              main: 'Instant',
              sentence: 'Articles instants',
            },
          },
          source: {
            main: 'Source',
            CREDIBLE: {
              main: 'Vérifiée',
              sentence: 'Source vérifiée',
            },
            NOT_CREDIBLE: {
              main: 'Non-Vérifiée',
              sentence: 'Source non-Vérifiée',
            },
          },
          publishDate: {
            main: 'Date de publication',
            include: "Inclure les articles sans date de publication",
            selectPresetRange: 'Sélectionnez',
            by: "Par ",
            from : "de",
            to: "à",
            fromStart: "De {{start}}",
            toEnd: "Jusqu'à {{end}}",
            range: "Publié entre {{start}} et {{end}}",
            startDate: "Publié depuis {{start}}",
            endtDate: "Publié avant {{end}}",
            noDate: "Sans date de publication",
            thisMonth: 'Ce mois-ci',
            custom: "Personaliser",
            yesterday: "Depuis hier",
            lastYear: "12 derniers mois",
            last6Months: '6 derniers mois',
            last3Months: '3 ans derniers',
            last2Years: '2 ans derniers',
            last5Years: '5 ans derniers',
            clear: 'Effacer',
            apply: 'Appliquer',
          },          
          subject: {
            main: "Role dans l’Article",
            TRUE: {
              main: 'Oui',
              sentence: "Sujet de l'Action",
            },
            FALSE: {
              main: 'Non',
              sentence: "Pas le Sujet de l'Action",
            },
            UNDEFINED:{
              main: 'Non analysé',
              sentence: "Non Analysé", 
            }
          },
          extendReport: {
            main: "Rapport d'étendue",
            TRUE: {
              main: 'Oui',
              sentence: 'Est un rapport étendu',
            },
            FALSE: {
              main: 'Non',
              sentence: "N'est pas un rapport étendu",
            },
          },
        },
        validations: {
          show_archived_only: 'Afficher les validations archivées',
          show_not_archived_only: 'Afficher les validations actives',
          show_all: 'Afficher toutes les validations',
          privacy: 'Confidentialité',
          show_private: 'Afficher les validations privées',
          show_public: 'Afficher les validations publiques',
          team: 'Équipe'
        },
      },
      theme: {
        main: 'Inconduite',
        ADDICTION: 'Dépendance',
        DISCRIMINATION: 'Discrimination',
        FRAUD: 'Fraude / Crime financier',
        HARASSMENT: 'Harcèlement',
        VIOLENCE: 'Violence',
        NEWS: 'Autres Nouvelles',
        LITIGATION: 'Conflits / Litiges',
        TWITTERFLAGS: 'Tweets identifiés',
      },
      lev_theme: {
        ENVIRONMENT: 'Environnement',
        SOCIAL: 'Social',
        GOVERNANCE: 'Gouvernance',
        OTHER: 'Autre',
      },
      conduct_summary: {
        title: "Les chiffres en un coup d'œil",
        how_to_read_this_report: 'Comment lire ce rapport',
        no_articles_found: 'Aucun article de {{misconduct}} trouvé',
        articles_analyzed:
          ' liens identifiés parmi plus de {{found}} analysés.',
      },
      twitter: {
        flagged_on: 'Signalé le',
        title:
          'Activité signalés pour inconduite pour <2>@{{twitter_screen_name}}</2>',
        tweets_flagged:
          '{{flagged}} tweets sur {{analyzed}} signalés dans la dernière analyse',
        tweets_flagged_date:
          '{{flagged}} tweets sur {{analyzed}} signalés dans la dernière analyse ({{start}} - {{end}})',
        no_twitter_account: {
          title:
            "Nous n'avons pas de nom d'utilisateur Twitter associé à cette personne.",
          subtitle:
            "Associez un compte Twitter ci-dessous ou mettez à jour la validation de l'individu.",
          button: 'Lancer une analyse Twitter',
        },
        no_tweets: {
          title:
            "Nous n'avons trouvé aucun tweets à analyser sur <2>@{{twitter_screen_name}}</2>",
          subtitle:
            "Cela peut être dû au fait que le compte est privé ou que le nom d'utilisateur n'existe pas.",
        },
        all_good: {
          title:
            'Nous avons analysé {{analyzed}} tweets et aucun tweet n’est à signaler pour @{{twitter_screen_name}}',
          zero_retrieved:
            'Nous avons trouvé 0 {{tweetsOrRetweets}} pour @{{twitter_screen_name}}',
        },
      },
      summary_details: {
        green_title: `Rien à signaler`,
        green_description1: `Des articles contenant des informations négatives au sujet de l’individu ont été détectés en ligne mais l’individu n’en serait pas responsable`,
        green_description3: `les articles détectés en ligne au sujet de l’individu ne contiennent pas des sujets d’inconduite.`,
        green_description_SECURITY: `Il n'y a pas d'informations en ligne sur l’individu, ou bien il n'y a pas de nouvelles 
        d’inconduites sur l’individu, ou bien l’individu est mentionné dans un article en lien avec un comportement d’inconduite 
        dans lequel il n'est pas le sujet de l'action. `,
        green_description_lev1: `Des articles contenant des informations négatives au sujet de l’organisation ont été détectés en ligne mais celle-ci n’en serait pas responsable`,
        OR: `OU`,
        green_description_lev3: `aucun article négatif mentionnant l’organisation n’a été détecté en ligne.`,
        yellow_title: 'Examiner',
        yellow_description1: `Des articles contenant des informations négatives au sujet de l’individu ont été détectés en ligne. Cependant, la corrélation de l’identifiant est faible (entre 60% et 79%)`,
        yellow_description3: `la source de l'article n'a pu être vérifiée.`,
        yellow_description_lev1: `Des articles contenant des informations négatives au sujet de l’organisation ont été détectés en ligne. Cependant, la corrélation de l’identifiant est faible (entre 60% et 79%)`,       
        yellow_description_lev3:`la source de l'article n'a pu être vérifiée.`,
        yellow_description_SECURITY: `L’individu est le sujet d'un article lié à un comportement d’inconduite mais la source de l'article n'est pas crédible ou bien le niveau d'identification de l’individu est faible (plus de 60% mais moins de 80%). Il serait préférable d'effectuer d’autres vérifications.`,
        red_title: 'Risque élevé',
        red_description: `Des articles provenant d’une source vérifiée et contenant des informations négatives au sujet de l’individu ont été détectés en ligne. De plus, la corrélation de l’identifiant est forte (80% et plus). Cette information pourrait avoir un impact sur votre évaluation du risque.`,
        red_description_SECURITY: `L’individu est le sujet d’un comportement d’inconduite selon un article de source crédible et le 
        niveau d'identification de l’individu est fort (80% et plus). Évaluez l’impact que cette information pourrait avoir sur votre organisation. `,
        red_description_lev: `Des articles provenant d’une source vérifiée et contenant des informations négatives au sujet de l’organisation ont été détectés en ligne. De plus, la corrélation de l’identifiant est forte (80% et plus). Cette information pourrait avoir un impact sur votre évaluation du risque.`,
        twitter_title: 'Tweets Signalés',
        twitter_description: `Les tweets et retweets sont signalés si le contenu et le contexte appartiennent aux cinq catégories d'inconduites. L'utilisation d'un langage grossier ou inapproprié est également signalée.`,
      },
      articles_found: {
        title: 'Informations trouvées',
        empty_title: 'Bonne nouvelle!',
        empty_description:
          "  Nous n'avons pas trouvé des articles pour cette section",
        source_is_credible: 'Crédibilité de la source validée',
      },
      profile: {
        year_of_birth: 'Année de naissance',
      },
      load_more: 'Charger plus',
    },
    lev_csv_form: {
      validate_from_csv: 'Valider à partir d’un fichier CSV',
      heading: 'Votre fichier CSV doit contenir les colonnes suivantes:',
      subheading:
        'Vous seriez en mesure de faire des modifications avant de soumettre.',
      download_template: 'Exemple de fichier',
      downloaded_template: 'Valital-CSV-Exemple.csv',
      upload_file: 'Téléverser le fichier',
      fix_errors: 'Vous devez corriger les erreurs avant de soumettre.',
      bullets: {
        organization_name: `Nom de l'organisation`,
        acronym: 'Acronyme (optionnel)',
        other_name: 'Autre nom (optionnel)',
        city: 'Ville de résidence (optionnel)',
        province: 'Province / État (optionnel)',
        country: 'Pays (optionnel)',
        industry: 'Industrie',
        live_update: 'EnContinu (oui ou non)',
      },
      error: {
        name_missing: `Nom de l'organisation manquant`,
        industry_missing: 'Industrie manquante',
      },
    },
    csv_form: {
      import_csv: 'Importer un fichier CSV',
      validate_from_csv: 'Valider à partir d’un fichier CSV',
      heading: 'Votre fichier CSV doit contenir les colonnes suivantes:',
      subheading:
        'Vous seriez en mesure de faire des modifications avant de soumettre.',
      download_template: 'Exemple de fichier',
      downloaded_template: 'Valital-CSV-Exemple.csv',
      upload_file: 'Téléverser le fichier',
      fix_errors: 'Vous devez corriger les erreurs avant de soumettre.',
      error: {
        first_name_missing: 'Prénom manquant',
        last_name_missing: 'Nom de famille manquant',
        last_name_invalid: 'Nom de famille invalide',
        first_name_invalid: 'Prénom invalide',
        city_invalid: 'Ville invalide',
        location_invalid: 'Emplacement invalide',
        roles_no_company: 'Titre ajouté sans organisation associée',
        too_many_companies: 'Plus de 3 organisations',
        too_many_nickname: 'Un surnom maximum',
        twitter_name_invalid: 'Identifiant Twitter invalide',
      },
      bullets: {
        first_name: 'Prénom',
        last_name: 'Nom de famille',
        nick_name: 'Surnom (optionnel)',
        alternative_first_name: 'Autre Prénom (optionnel)',
        // year_of_birth: 'Année de naissance',
        city: 'Ville de résidence',
        province: 'Province / État',
        country: 'Pays',
        year_of_birth: 'Année de naissance (optionnel)',
        company1:
          'Entreprise 1 (Une entreprise à laquelle la personne est affiliée)',
        role1: "Role 1 (Role dans l'entreprise 1)",
        company2:
          'Entreprise 2 (Une entreprise à laquelle la personne est affiliée)',
        role2: "Role 2 (Role dans l'entreprise 2)",
        company3:
          'Entreprise 3 (Une entreprise à laquelle la personne est affiliée)',
        role3: "Role 3 (Role dans l'entreprise 3)",
        twitter: 'Nom d’utilisateur Twitter (optionnel)',
        live_update: 'EnContinu (oui ou non)',
        extended_report: 'Recherche étendue (oui ou non)',
      },
    },
    lev_validation_form: {
      title: 'Organisation à valider',
      fields: {
        organization_name: `Nom de l'organisation`,
        acronym: 'Acronyme (optionnel)',
        other_name: 'Autre nom (optionnel)',
        industry: 'Industrie',
        location_empty:
          'Remplir si l’organisation est incorporée/enregistrée dans une seule juridiction, ou si vous souhaitez optimiser vos résultats de recherche.',
      },
      user_suggestions:
        "Une ou plusieurs organisations portant ce nom existent déjà. Cliquez sur l'un des noms ci-dessous pour mettre à jour leur rapport.",
      spelling_fix:
        'Nous avons détecté sur l’internet le nom d’organisation similaire ci-dessous. Cliquez dessus si vous souhaitez remplacer le nom que vous avez saisi.',
    },
    validation_form: {
      spelling_fix: 'Nous avons détecté sur l’internet le nom similaire ci-dessous. Cliquez dessus si vous souhaitez remplacer le nom que vous avez saisi.',
      last_validated_on: 'Dernière mise à jour le',
      nothing_found: `Rien n'a été trouvé`,
      show_more_tweets: 'Afficher {{count}} résultats supplémentaires',
      submit_and_new: 'Soumettre et nouveau',
      submit_more: 'Soumettre plus',
      submit_and_close: 'Soumettre la validation',
      next: 'Suivant',
      cancel: 'Annuler',
      previous: 'Précédent',
      privacy_PRIVATE: 'Privée',
      privacy_PUBLIC: 'Publique',
      dont_include_twitter: `Ne pas inclure Twitter`,
      year_of_birth: 'Année de naissance (optionnel)',
      edit_twitter: {
        title: "Voulez-vous changer le nom d'utilisateur Twitter?",
        description:
          "La modification du nom d'utilisateur supprimera tous les tweets marqués de l'autre compte.",
      },
      title: 'Effectuer une nouvelle validation',
      description_editing: '',
      user_suggestions:
        "Une ou plusieurs personnes portant ce nom existent déjà. Cliquez sur l'un des noms ci-dessous pour mettre à jour leur rapport.",
      add_companies:
        'Entreprises auxquelles la personne est affiliée (maximum de 3)',
      positions_occupied:
        'Voici les organisations et les titres pour chacune de celle-ci que cette personne a occupé',
      add_roles: 'Add roles that this person occupied (recommended)',
      twitter_username: "Nom d'utilisateur Twitter",
      add_twitter_username:
        'Inclure un nom d’usager Twitter pour effectuer son analyse (optionnel)',
      search_twitter_user: `Aucune de ces réponses? Entrez un nom d'utilisateur Twitter`,
      select_twitter_user: `Nous avons trouvé les noms d'utilisateur Twitter suivants qui pourraient appartenir à cette personne. Sélectionnez-en un pour inclure Twitter dans votre rapport.`,
      add_companies_editing:
        'Ajoutez ou supprimez des entreprises et des roles pour modifier les résultats',
      helper_text_company:
        'Appuyez sur Entrée pour ajouter une autre entreprise',
      helper_text_role:
        'Appuyez sur Entrée pour ajouter une autre entreprise & rôle',
      no_results:
        'Effectuer votre première validation en cliquant sur le bouton en haut à gauche',
      no_results_mobile:
        'Aucune Validation ! Exécutez votre première validation en cliquant sur le bouton en bas.',
      tooltip:
        'Abonnement annuel en cours. Les totaux peuvent inclure des validations multiples du même individu et des validations archivées.',
      filters: {
        max_date_error: 'Doit être avant la date de fin',
        min_date_error: 'Doit être après la date de début',
      },
    },
    search_by_city: 'Recherche de personnes par ville',
    validation_privacy: {
      public_info: "Cette validation sera accessible par tous les membres de votre équipe",
      private_info_MEMBER: "Cette validation sera accessible par vous-même, les administrateurs de votre équipe, les administrateurs du compte et le responsable du compte",
      private_info_LEAD: "Cette validation sera accessible par vous-même, les administrateurs du compte et le responsable du compte",
      private_info_ADMIN: "Cette validation sera accessible par vous-même et le responsable du compte",
      private_info_OWNER: "Cette validation ne sera accessible qu’à vous-même"
    },
    csv_validation_usage: {
      validations_remaining: 'Vous avez {{value}} validations restantes à travers le CSV pour aujourd’hui.',
      zero_validations_remaining: 'Vous n’avez aucune validation restante via CSV pour aujourd’hui. Veuillez soumettre vos validations de façon individuelle.',
      limit_exceeded: 'Vous avez atteint votre limite CSV. Vous devez retirer {{value}} validations avant de soumettre.',
    }
  },
  labels:{
    labelsFindCreate: "Sélectionner ou créer une étiquette",
    labelsConfirmDelete: 'Êtes-vous sur de vouloir effacer l\'étiquette "{{name}}" ?',
    createLabel: "Créer une étiquette",
    yes: "Oui",
    no: "No",
    cancel: "Annuler",
    more: "Montrer plus",
    save: "Sauvegarder"
  }
};
